<template>
  <div>
    <v-container class="text-center">
      <h2 class="mb-15">Tiro desviado…</h2>
      <p class="lead"><strong>Error 404, página no encontrada.</strong></p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style scoped>
h2 {
  font-size: 5rem;
  line-height: 1;
}

p.lead {
  font-family: "Spartan", sans-serif;
  line-height: 2.5;
  font-size: 1rem;
  font-weight: 500;
}

/* 600px and down */
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 3.5rem;
  }
}
</style>
